const IconLoader = () => (
  <svg
    id="logo"
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="92"
    viewBox="0 0 80 92"
  >
    <title>Loader Logo</title>
    <g>
      <g id="B">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.4189 39L41.7143 42.6359L32.4587 51.5714L31.8778 50.9726L40.5532 42.5973L38.3913 40.212L29.7364 48.9309L29.1429 48.3446L38.4189 39Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.0651 39L51.4286 48.3458L50.7992 48.9227L42.0519 40.192L39.5319 42.6024L48.6595 50.9694L48.0563 51.5714L38.2857 42.6151L42.0651 39Z"
          fill="currentColor"
        />
        <path
          d="M23.5124 61.4248L8 28.2864C8.18483 27.7982 8.27758 27.5212 8.41952 27C8.83411 27.1519 9.11237 27.2442 9.80655 27.4466L25.064 59.8804L39.9987 47.7186L54.9334 59.8804L70.1903 27.4478C70.1903 27.4478 71.1649 27.1519 71.5789 27.0006C71.7215 27.5212 71.8142 27.7982 72 28.2863L56.679 61.2318L57.6488 62.0039V65.2857L56.0971 63.7413L39.9987 47.7186L23.9003 63.7413L22.5426 65.0927V62.39L23.5124 61.4248Z"
          fill="currentColor"
        />
        <path
          d="M40.561 32.1429L19.4286 52.44L21.2987 52.7143L40.561 34.1543L58.7948 52.7143H60.5714L40.561 32.1429Z"
          fill="currentColor"
        />
      </g>
      <circle cx="40" cy="41" r="38" stroke="currentColor" />
    </g>
  </svg>
)

export default IconLoader
