const IconBehance = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    viewBox="0 0 85 85"
    fill="currentColor"
    stroke="currentColor"
    strokeWidth="4"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-codepen"
  >
    <path d="M45.087,44.576c1.013-1.49,1.526-3.365,1.526-5.573c0-2.031-0.34-3.763-1.012-5.148c-0.687-1.403-1.665-2.546-2.911-3.398  c-1.253-0.843-2.739-1.451-4.417-1.807c-1.691-0.366-3.518-0.544-5.584-0.544H15.886c-0.276,0-0.5,0.224-0.5,0.5v42.002  c0,0.276,0.224,0.5,0.5,0.5h17.358c1.922,0,3.812-0.243,5.616-0.724c1.856-0.488,3.518-1.246,4.941-2.254  c1.46-1.033,2.629-2.372,3.475-3.978c0.85-1.607,1.281-3.539,1.281-5.739c0-2.725-0.668-5.088-1.987-7.024  c-1.141-1.672-2.789-2.924-4.909-3.732C43.11,46.815,44.259,45.783,45.087,44.576z M45.744,51.952  c1.22,1.792,1.813,3.905,1.813,6.461c0,2.036-0.392,3.81-1.165,5.272c-0.771,1.466-1.837,2.687-3.168,3.628  c-1.324,0.938-2.878,1.646-4.618,2.104c-1.721,0.458-3.525,0.69-5.36,0.69H16.386V48.259H40.31  C42.722,48.964,44.552,50.206,45.744,51.952z M40.262,47.259H16.386V29.105h16.303c1.995,0,3.752,0.17,5.375,0.521  c1.553,0.329,2.922,0.888,4.064,1.657c1.099,0.751,1.965,1.764,2.574,3.008c0.604,1.248,0.911,2.832,0.911,4.711  c0,2.004-0.455,3.689-1.352,5.009C43.36,45.326,42.011,46.416,40.262,47.259z" />
    <path d="M54.439,48.884c-0.598,1.58-0.966,3.267-1.099,5.03c-0.004,0.026-0.007,0.054-0.007,0.082c0,0.006,0,0.011,0,0.017  c-0.032,0.465-0.049,0.935-0.049,1.409c0,2.404,0.378,4.644,1.123,6.657c0.746,2.004,1.82,3.766,3.197,5.24  c1.426,1.481,3.089,2.59,5.088,3.389c1.996,0.787,4.24,1.187,6.671,1.187c3.538,0,6.592-0.824,9.077-2.446  c1.898-1.221,3.447-3.072,4.603-5.504c0.119-0.25,0.012-0.548-0.237-0.666c-0.25-0.12-0.548-0.013-0.667,0.236  c-1.074,2.261-2.501,3.974-4.243,5.094c-2.322,1.517-5.193,2.286-8.533,2.286c-2.305,0-4.426-0.376-6.302-1.115  c-1.862-0.745-3.411-1.776-4.731-3.147c-1.299-1.392-2.276-2.995-2.985-4.901c-0.704-1.901-1.061-4.023-1.061-6.309  c0-0.309,0.007-0.614,0.022-0.918l29.804,0.24c0.131,0.03,0.273-0.056,0.369-0.158c0.095-0.102,0.144-0.238,0.134-0.378  c-0.09-1.254-0.289-2.495-0.592-3.69c-0.57-2.236-1.497-4.236-2.754-5.944c-1.287-1.752-2.941-3.157-4.914-4.178  c-1.982-1.035-4.334-1.561-6.989-1.561c-2.375,0-4.576,0.431-6.541,1.279c-1.952,0.846-3.665,2.023-5.089,3.502  C56.336,45.062,55.229,46.833,54.439,48.884z M63.219,41.032c1.839-0.795,3.906-1.197,6.144-1.197c2.492,0,4.688,0.487,6.528,1.448  c1.837,0.949,3.375,2.256,4.569,3.882c1.181,1.604,2.053,3.489,2.591,5.6c0.245,0.966,0.418,1.964,0.518,2.975L54.38,53.505  c0.152-1.493,0.485-2.921,0.994-4.265c0.75-1.949,1.757-3.562,3.078-4.93C59.785,42.928,61.389,41.824,63.219,41.032z" />
    <path d="M77.488,31.923c0.276,0,0.5-0.224,0.5-0.5s-0.224-0.5-0.5-0.5H60.463c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5H77.488z  " />
  </svg>
)

export default IconBehance
